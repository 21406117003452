import React from "react";
import notfound from '../images/notfound.jpg';
//import {Navbar,Nav,Form,FormControl,Button,NavDropdown,ButtonToolbar} from 'react-bootstrap';
//Functional Component 
import { Result, Button } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
function NotFoundPage(){
  return (
    <div>
     <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button type="primary" href="/">Back Home</Button>}
  />
    </div>
  );
};

export default NotFoundPage;