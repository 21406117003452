import React from 'react';
import Successimg from '../images/success.png'
import Header from '../header/index';
import Footer from '../footer/index'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Success extends React.Component{
    render(){
        return(
            <div>
                <Header />
                <Container>
                    <Row>
                    <Col sm={12}>
                            <h3 style={{color:"#343A40",margin:"30px"}}>
                            Successfully Submitted
                            </h3>
                        </Col>
                    <Col sm={12}>
                <img src={Successimg} width="25%" alt="Success" />
                </Col>
            </Row>
            </Container>
                <Footer />
            </div>
        )
    }
}


export default Success;