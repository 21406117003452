import React, { Component } from 'react';
import Button from '@material-ui/core/Button'
import { Navbar, Container } from 'react-bootstrap'
import logo from './logo.png'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import log from './login.jpg'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Header from '../header';
import axios from 'axios';

import MCard from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import MButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            username: '',
            password: '',
            redirectToReferrer: false,
            apiResponse: [],
            error: false,
        };
        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    login = () => {
        const formData = new FormData();
        formData.append('email', this.state.username)
        formData.append('password', this.state.password)
        
        // axios.post('http://localhost/api/react/web/oxy/imperalsol/login.php', formData, {
        axios.post('https://imperialsol.com/api/login.php', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })

            .then(response => {
                console.log(response.data);
                const { apiResponse } = response.data;
                if (apiResponse[0].status === "success") {
                    sessionStorage.setItem('userData', JSON.stringify(apiResponse));
                    this.setState({ redirectToReferrer: true, error: false });
                } else {
                    this.setState({
                        redirectToReferrer: false,
                        error: true
                    });
                }
            });
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        if (this.state.redirectToReferrer) {
            return (<Redirect to={'/dashboard'} />)
        }

        return (
            <div style={{ backgroundColor: '#18212e' }}>
                <Header />
                <br />
                <Container style={{marginLeft : '30%'}}>
                    <MCard style={{ width: '50%', marginTop : '16%' }}>
                        <CardContent>
                            <Typography style={{ fontSize: '24px', textAlign: 'center' }}>
                                Login
                        </Typography>
                            <Divider />
                            <Typography variant="h5" component="h2">
                                {this.state.error === true ? <h5 style={{ color: 'red' }}>Username are Password Error</h5> : null}
                            </Typography>

                            <TextField
                                label="Email *"
                                id="margin-none"
                                style={{ width: '60%' }}
                                name="username"
                                onChange={this.onChange}

                            />
                            <br />
                            <TextField
                                label="Password *"
                                id="margin-none"
                                style={{ width: '60%' }}
                                type="password"
                                name="password"
                                onChange={this.onChange}
                            />
                            <br /> <br />
                            <Button disabled={!(this.state.username && this.state.password)} type="submit" variant="contained" color="primary" className="button" value="Login" onClick={this.login}>
                                Login
                            </Button>
                            <br />
                            <br />
                            Does not have an account? <Card.Link href="/signup">Register</Card.Link>
                        </CardContent>
                    </MCard>
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                    <br /><br /><br />
                </Container>
            </div>
        )
    }
}


export default Login;