import React from 'react';
import Banner from './banner'
import Header from '../header/index';
import Aboutus from '../img/about.jpg';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from '../footer/index'
import icon1 from '../img/icons/1.svg'
import icon2 from '../img/icons/2.svg'
import icon3 from '../img/icons/3.svg'
import { Link } from 'react-router-dom';

class About extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <Banner />
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h3 style={{ color: "#343A40", margin: "30px" }}>
                                About Us
                            </h3>
                        </Col>
                        
                        <Col sm={6}><img src={Aboutus} style={{ width: "80%", borderRadius: "10px", marginBottom: "50px" }} /></Col>
                        <Col sm={6}>
                            <p style={{ fontSize : '17px', textAlign: "justify", marginTop: "10px" }}>&nbsp;&nbsp;Imperial Solutions is the technology provider  firm  focused exclusively on the financial technology sector. We broadly define the sector as the dynamic convergence of technology-based solutions and financial services. </p>
                            <p style={{ fontSize : '17px', textAlign: "justify" }}>&nbsp;&nbsp;Clients want and deserve the best in industry knowledge and technology services. In the complex financial technology sector, we believe in offering best solutions in the market.  Imperial Solutions have its own unique approach towards its client requirements and   to maximize shareholders values.</p>
                        </Col>
                        
                        
                        <Col sm={12}>
                            <h3 style={{ color: "#343A40", margin: "1%" }}>
                                Our Services
                            </h3>
                        </Col>

                        <Col sm={4}>
                            <Link to="/operation">  <img src={icon1} width="16%" alt="Data1" style={{ marginTop: "10px" }} /></Link><br></br>
                            <Link to="/operation" style={{ textAlign: "center", color: "#343A40", marginTop: "10px" }}>Operations And Processing</Link>
                        </Col>
                        <Col sm={4}>
                            <Link to="/data_analysis"><img src={icon2} width="16%" alt="Data1" style={{ marginTop: "10px" }} /></Link><br></br>
                            <Link to="/data_analysis" style={{ textAlign: "center", color: "#343A40" }}>Market Data and Analysis</Link>
                        </Col>
                        <Col sm={4} style={{ textAlign: "center", marginBottom: "10px" }}>
                            <Link to="/consulting"><img src={icon3} width="16%" alt="Data1" style={{ marginTop: "10px" }} /></Link><br></br>
                            <Link to="/consulting" style={{ textAlign: "center", marginBottom: "500px", color: "#343A40" }}>Functional Consulting</Link>
                        </Col>
                    </Row>
                </Container>
                <Footer />

            </div>
        )
    }
}

export default About;