import React from 'react';
import Header from '../header/index';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './service.css'
import Footer from '../footer/index'
import Managementt from '../images/management.jpg'
import Mgt1 from '../images/mgt/m1.svg'
import Mgt2 from '../images/mgt/m2.svg'
import Mgt3 from '../images/mgt/m3.svg'
import Mgt4 from '../images/mgt/m4.svg'
import Mgt5 from '../images/mgt/m5.svg'
import Mgt6 from '../images/mgt/m6.svg'

class Management extends React.Component{
    render(){
        return(
            <div>
                <Header />
                <img src={Managementt} width="100%" alt="Management" />
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h3 style={{color:"#343A40",margin:"30px"}}>
                                Enterprise Management Solutions
                            </h3>
                        </Col>
                        <Col sm={12}>
                        <p className="full">ERP refers to Enterprise and resource planning. An ERP system is used to completely automate and integrate all the work flows of the core business along with financial data.</p>
                        <p className="full">ERP systems can bring very effective improvements in an organization both economically and ethically as follows:</p>
                        </Col>
                         <Col sm={6}>
                         <img src={Mgt1} width="16%" alt="Mgt1" />
                            <h5>Automation enhances productivity </h5>
                            <p>By automating aspects of business processes, ERP makes them more efficient, less prone to error, and faster. It also frees up people from mundane tasks </p>
                            <img src={Mgt6} width="16%" alt="Mgt1" />
                            <h5>Financial Data Management </h5>
                            <p>The most important aspect of any business is the financial wellbeing of the company. With the integration of our ERP your management will be able to analyze and audit the complete financial data from sales, purchase, payroll, </p>
                            <img src={Mgt3} width="16%" alt="Mgt1" />
                            <h5>Integration of all workflows under a single platform </h5>
                            <p>An ERP system can fully integrate into all aspects of your business from the customer facing front end, through planning and scheduling, to the production and distribution of the products you make. Thus it helps the management to streamline the work flow of the business and gives a complete control, detailed information of each and every work flow happening inside the firm. </p>
                         </Col>
                         <Col sm={6}>
                         <img src={Mgt4} width="16%" alt="Mgt1" />
                            <h5>Quality Reports and Performance Analysis </h5>
                            <p>Analysis on ERP will enable you to produce financial and boardroom quality reports, as well as to conduct analysis on the performance of your organization. </p>
                            <img src={Mgt5} width="16%" alt="Mgt1" />  
                            <h5>Integrates across the entire supply chain </h5>
                            <p>A best of breed ERP system should extend beyond your organization and integrate with both your supplier and customer systems to ensure full visibility and efficiency across your supply chain. </p>
                            <img src={Mgt2} width="16%" alt="Mgt1" />
                            <h5>Increase overall performance </h5>
                            <p style={{marginBottom:"150px"}}>By integrating disparate business processes, ERP ensures coherence and avoids duplication, discontinuity, and people working at cross purposes, in different parts of the organization. The cumulative positive effect when business processes integrate well is overall superior performance by the organization. </p>
                            
                         </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default Management;