import React from 'react';
import Header from '../header/index';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Crmimg from '../images/crm.png';
import './service.css'
import Footer from '../footer/index'
import Crmm from '../images/crm.jpg'

class Crm extends React.Component{
    render(){
        return(
            <div>
                <Header />
                <img src={Crmm} width="100%" alt="Crmm" />
                     
                <Container>
                    <Row>
                      <Col sm={12}>
                            <h3 style={{color:"#343A40",margin:"30px"}}>
                            CRM Solutions
                            </h3>
                        </Col>
                        <Col sm={6}>
                            <p style={{marginTop:"50px"}}>Customer Relationship Management is a key source to improve productivity of a business by integrating it with Enterprise Resource Planning.</p>
                            <p>One important area where organizations can use technology to improve productivity is by removing data silos and integrating Customer Relationship Management (CRM) with their Enterprise Resource Planning (ERP) solution. The traditional way of using CRM includes customer contacts, classifications, pipeline management and different memos but IMsolution CRM gives you this information and much more always up to date. You can register and classify all your business related contacts not only customers and prospects but also vendors and other relevant contacts</p>
                        </Col>
                        <Col sm={6}><img src={Crmimg} style={{width: "80%",borderRadius:"10px", marginBottom:"100px"}} /></Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default Crm;