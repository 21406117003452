import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Banner1 from '../img/homebanner1.jpg';
import Banner2 from '../img/homebanner2.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';

class Banner extends React.Component{
    render(){
        return(
            
              <Carousel>
  <Carousel.Item>
    <img
     className="d-block w-100"
      src={Banner1}
      alt="First slide"
    />
    {/* <Carousel.Caption>
      <h3>Trade with a secure global forex Broker</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </Carousel.Caption> */}
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={Banner2}
      alt="Third slide"
    />

    {/* <Carousel.Caption>
      <h3>The fastest way to achieve Success</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </Carousel.Caption> */}
  </Carousel.Item>
  {/* <Carousel.Item>
    <img
      className="d-block w-100"
      src={Banner3}
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3>Trade Forex with Ultra Low Spreads</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption>
  </Carousel.Item> */}
</Carousel>
            
        )
    }
}


export default Banner;