import React from 'react';
import Header from '../header/index';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './service.css'
import Footer from '../footer/index'
import Consultingg from '../img/tab5-home.jpg'
import Data1 from '../img/icons/5-1.svg'
import Data2 from '../img/icons/5-2.svg'
import Consult from '../img/tab5-1.jpg';

class Consulting extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <img src={Consultingg} width="100%" alt="Consulting" />
                <Container>
                    <Row style={{ margin: "30px" }}>
                        <Col sm={6}>
                            <img src={Data1} style={{ width: "30%", borderRadius: "10px" }} />
                        </Col>
                        <Col sm={6}>
                            <h4 style={{ color: "#343A40", textAlign : 'left' }}>
                                Functional Consulting
                            </h4>
                            <p style={{ fontSize: '18px', textAlign: 'justify' }}>
                                &nbsp;&nbsp;Software Requirement Analysis is the important phase of each software development process. We understand client requirements to produce the software which match with clients requirements.
                            </p>
                        </Col>
                    </Row>

                    <Row style={{ margin: "30px" }}>
                        <Col sm={6}>
                            <h4 style={{ color: "#343A40", textAlign : 'left' }}>
                                SOFTWARE REQUIREMENT ANALYSIS
                            </h4>
                            <p style={{ fontSize: '18px', textAlign: 'justify' }}>
                                &nbsp;&nbsp;Our team is well expertized in requirement gathering and analyzing. They can understand our client’s requirement very well and set the configuration level by researching on available options. A detailed system requirement document prepared by our functional consulting team will be utilized by ERP development team. The resultant application will be the best ERP solution with entitled requirements of our clients.
                            </p>
                        </Col>
                        <Col sm={6}>
                            <img src={Data2} style={{ width: "30%", borderRadius: "10px" }} />
                        </Col>
                    </Row>

                    <Row style={{marginBottom : '5%'}}>
                        <Col sm={6}>
                            <img src={Consult} style={{ width: "80%", marginTop : '2%' }} />
                        </Col>
                        <Col sm={6}>
                            <h4 style={{ color: "#343A40", textAlign : 'left' }}>
                                IMPORTANCE OF FUNCTIONAL CONSULTING
                            </h4>
                            <p style={{ fontSize: '18px', textAlign: 'justify' }}>
                                &nbsp;&nbsp;The functional consulting i.e the requirement gathering is a most important and very first step of the ERP software development. A proper requirement gathering documentation only would offer a solution that comprises customer demands. Only functional consultants can able to evaluate the demand of each client as they have in depth knowledge on business processes. 
                            </p>
                            <p style={{ fontSize: '18px', textAlign: 'justify' }}>
                                &nbsp;&nbsp;A successful ERP Solution implementation of a business is totally depends on a proper requirement gathering phase. Imperial Solutions  highly concentrates on this important phase of each ERP software development to provide the best to its valuable clients across the globe.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default Consulting;