import React from 'react'
import Header from './header';
import Dashboardd from '../img/3tab3-home.jpg'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from '@material-ui/core/Button'
import Footer from '../footer/index'
import Form from 'react-bootstrap/Form'
import { Redirect } from 'react-router-dom';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      invoice: '',
      service: '',
      userFeed: '',
      redirectToReferrer: false,
      name: '',
    };
    //  this.invoice = this.invoice.bind(this);
    this.onChange = this.onChange.bind(this);
    this.logout = this.logout.bind(this);
  }
  componentWillMount() {
    if (sessionStorage.getItem("userData")) {
    }
    else {
      this.setState({ redirectToReferrer: true });
    }
  }

  logout() {
    sessionStorage.setItem("userData", '');
    sessionStorage.clear();
    this.setState({ redirectToReferrer: true });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    if (this.state.redirectToReferrer) {
      return (<Redirect to={'/'} />)
    }

    return (
      <div>
        <Header />
        <img src={Dashboardd} width="100%" alt="Consulting" />
        <Container>
          <Row>
            <Col sm={12}>
              <h3 style={{ color: "#343A40", margin: "30px" }}>
                WELCOME
                            </h3>
            </Col>
            <Col sm={4}>
            </Col>
            <Col sm={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField id="standard-basic" label="Invoice Id" name="invoice" onChange={this.onChange} />
              </FormControl>

              <br /><br />
              <FormControl style={{ width: '100%' }}>
                <InputLabel id="demo-controlled-open-select-label">Select</InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  name="service"
                  onChange={this.onChange}
                >
                  <MenuItem value={1}>ENTERPRISE MANAGEMENT</MenuItem>
                  <MenuItem value={2}>MARKETING, SALES & PURCHASE MANAGEMENT</MenuItem>
                  <MenuItem value={6}>FUNCTIONAL CONSULTING</MenuItem>
                </Select>
              </FormControl>
              <br /><br /><br />
              <Button disabled={!(this.state.invoice && this.state.service)} type="submit" variant="contained" color="primary" className="button" href="/succes" style={{ marginBottom: "100px" }}>

                Submit
                                    </Button>


            </Col>
            <Col sm={4}>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    )
  }
}

export default Dashboard;