import React from 'react';
import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect
} from "react-router-dom";
import Home from "./pages/home/index"
import About from "./pages/about/index"
import NotFoundPage from "./pages/404/index"
import Operations from "./pages/service/operations"
import Data from "./pages/service/data"
import Management from "./pages/service/management"
import Consulting from "./pages/service/consulting"
import Crm from "./pages/service/crm"
import Contact from "./pages/contact/index"
import Login from "./pages/Login/index"
import Register from "./pages/Register/index"
import Dashboard from "./pages/dashboard/index"
import Success from "./pages/success/index"
import Succes from "./pages/dashboard/succes"
import './App.css';

function App() {
  return (
    <div className="App">
     
      <Router>
       <Switch>
        <Route exact path="/" component={About} />
        <Route exact path="/404" component={NotFoundPage}/>
        <Route exact path="/about" component={About}/>
        <Route exact path="/operation" component={Operations}/>
        <Route exact path="/data_analysis" component={Data}/>
        <Route exact path="/management" component={Management}/>
        <Route exact path="/consulting" component={Consulting}/>
        <Route exact path="/crm" component={Crm}/>
        <Route exact path="/contact" component={Contact}/>
        <Route exact path="/signin" component={Login}/>
        <Route exact path="/signup" component={Register}/>
        <Route exact path="/dashboard" component={Dashboard}/>
        <Route exact path="/success" component={Success}/>
        <Route exact path="/succes" component={Succes}/>
        <Redirect to="/404" />
       </Switch>
      </Router>
    </div>
  );
}

export default App;
