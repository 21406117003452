import React from 'react';
import Header from '../header/index';
// import Banner from './banner';



class Home extends React.Component{
    render(){
        return(
            <div>
                <Header />
                {/* <Banner /> */}

            </div>
        )
    }
}


export default Home;