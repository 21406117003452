import React from 'react'
import { Navbar, Container } from 'react-bootstrap'
import logo from './logo.png'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TextField from '@material-ui/core/TextField';
import log from './login.jpg'
import Header from '../header';
import axios from 'axios';


import MCard from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import MButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            email: '',
            name: '',
            redirectToReferrer: false
        };
        this.signup = this.signup.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    signup() {
        const formData = new FormData();
        formData.append('email', this.state.email)
        formData.append('password', this.state.password)
        formData.append('username', this.state.username)
        formData.append('name', this.state.name)

        // axios.post('http://localhost/api/react/web/oxy/imperalsol/register.php', formData, {
            axios.post('https://imperialsol.com/api/register.php', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })

            .then(response => {
                console.log(response.data);
                const { apiResponse } = response.data;
                if (apiResponse[0].status === "success") {
                    sessionStorage.setItem('userData', JSON.stringify(apiResponse));
                    this.setState({ redirectToReferrer: true, error: false });
                } else {
                    this.setState({
                        redirectToReferrer: false,
                        error: true
                    });
                }
            });
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        if (this.state.redirectToReferrer || sessionStorage.getItem('userData')) {
            return (<Redirect to={'/success'} />)
        }
        return (
            <div style={{ backgroundColor: '#18212e' }}>
                <Header />
                <Container style={{ marginLeft: '30%' }}>
                    <MCard style={{ width: '50%', marginTop: '16%' }}>
                        <CardContent>
                            <Typography style={{ fontSize: '24px', textAlign: 'center' }}>
                                Register
                        </Typography>
                            <Divider />
                            <Typography variant="h5" component="h2">
                                {this.state.error === true ? <h5 style={{ color: 'red' }}>Username are Password Error</h5> : null}
                            </Typography>

                            <TextField
                                required
                                label="Name"
                                id="margin-none"
                                style={{ width: '60%' }}
                                name="username"
                                onChange={this.onChange}
                            />
                            <br />
                            <TextField
                                required
                                label="Email"
                                id="margin-none"
                                style={{ width: '60%' }}
                                name="email"
                                onChange={this.onChange}
                            />
                            <br />


                            <TextField
                                required
                                label="Phone Number"
                                id="margin-none"
                                style={{ width: '60%' }}
                                name="name"
                                onChange={this.onChange}
                            />
                            <br />

                            <TextField
                                required
                                label="Password"
                                id="margin-none"
                                style={{ width: '60%' }}
                                type="password"
                                name="password"
                                onChange={this.onChange}
                            />
                            <br />
                            <br /><br />

                            <Button disabled={!(this.state.username && this.state.password && this.state.email && this.state.name)} type="submit" variant="contained" color="primary" className="button" value="Sign Up" onClick={this.signup} >
                                Register
                                </Button>
                            
                            <br />
                            <br />
                            Already have an account? <Card.Link href="/signin">Login</Card.Link>
                        </CardContent>
                    </MCard>
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                </Container>
            </div>
        )
    }
}


export default Signup;