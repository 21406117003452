import React from 'react';
import Header from '../header/index';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './service.css'
import Footer from '../footer/index'
import Process from '../img/tab3-1.jpg'
import Technology from '../img/3tab3-1.jpg'
import Operation from '../img/3tab3-2.jpg'

class Operations extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <img src={Process} width="100%" alt="hot" />
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h3 style={{ color: "#343A40", margin: "30px" }}>
                                Operations And Processing
                            </h3>
                        </Col>
                        <Col sm={6}>
                            <img src={Technology} style={{ width: "70%", borderRadius: "10px" }} />
                        </Col>
                        <Col sm={6}>
                            <h5 style={{textAlign : 'left'}}>Empowering the Technology {`&`} Processing</h5>
                            <p style={{fontSize : '17px', textAlign : 'justify'}}>For investment banks and brokerages, the greatest opportunity to power growth in the years ahead is through cost reductions, automation, and streamlined processes. </p>
                            <p style={{fontSize : '17px', textAlign : 'justify'}}>With our solutions can explore new ways to reduce costs while enhancing the user and investor experience. We make identifying and implementing automated workflows as turnkey as possible so your analysts can free up precious time to work on new deal and trade opportunities.</p>
                        </Col>

                    </Row>
                    <Row style={{marginBottom : '5%'}}>
                        <Col>
                            <h5 style={{textAlign : 'left'}}>Step Up Now for Smarter Operations, Total E2E Support </h5>
                            <p style={{fontSize : '17px', textAlign : 'justify'}}>IMsoluitons Tech supports the full trading system with end to end solution and with real-time data on a single integrated platform.</p>
                            <p style={{fontSize : '17px', textAlign : 'justify'}}>Backed by decades of deep industry experience, innovation and strategic partnership, broker-dealers can refocus on their real business – from trading and order management to settlement and asset servicing – while giving their customers actionable access that satisfies their new demands.</p>
                        </Col>
                        <Col sm={6}>
                            <img src={Operation} style={{ width: "70%", borderRadius: "10px" }} />
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default Operations;