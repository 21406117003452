import React from 'react';
import Successimg from '../images/success.png'
import Header from './header';
import Footer from '../footer/index'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dashboardd from '../images/dashboard.jpg'
import Alert from 'react-bootstrap/Alert'
import Button from '@material-ui/core/Button'

class Succes extends React.Component{
    render(){
        return(
            <div>
                <Header />
                <img src={Dashboardd} width="100%" alt="Consulting" />
                <Container>
                    <Row>
                    {/* <img src={Successimg} width="10%" alt="Success" style={{marginBottom:"10px"}} /> */}
                    <Col sm={12}>
                            {/* <h3 style={{color:"#343A40",margin:"30px"}}>
                            Thanks for Submitting. Our team will reach you soon.
                            </h3> */}
                            <Alert variant="success" style={{marginTop:"100px",marginBottom:"100px"}}>
                                <Alert.Heading> Dear IMPERALSOL Customer, Thanks for Submitting!</Alert.Heading>
                                <hr />
                                <p style={{textAlign:"center"}}>
                                One of our colleagues will get back in touch with you soon!
                                </p>
                              
                                {/* <p className="mb-0">
                                    Whenever you need to, be sure to use margin utilities to keep things nice
                                    and tidy.
                                </p> */}
                                </Alert>
                                <Button color="primary" variant="contained" size="large" href="/Dashboard">Done</Button>
                        </Col>
                    <Col sm={12}>
              
                </Col>
            </Row>
            </Container>
                <Footer />
            </div>
        )
    }
}


export default Succes;