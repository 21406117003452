import React from 'react';
import Header from '../header/index';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './service.css'
import Footer from '../footer/index'
import Analysis from '../img/tab4-home.jpg'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

class Data extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <img src={Analysis} width="100%" alt="Analysis" />

                <Container style={{marginTop : '2%'}}>
                    <h3 style={{ color: "#343A40", margin: "30px" }}>
                                Market Data and Analysis
                    </h3>

                    <Row>
                        <Col sm={4}>
                            <Card>
                                <CardActionArea>
                                    <CardMedia style={{height : 180}}
                                        image={require ("./../img/tab4-1.jpg")}
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2" style={{textAlign : 'left'}}> 
                                            <p>Empowering Trade system</p>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p"
                                            style={{textAlign : 'justify', fontSize : '16px'}}
                                        >
                                        <p >Ready for a True Front-to-back Trading System?</p>
                                        <p >Our Tech delivers real-time cross-asset trading, order management, portfolio management, risk management, position keeping, general ledger and more. </p>
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Col>
                        <Col sm={4}>
                            <Card>
                                <CardActionArea>
                                    <CardMedia style={{height : 188}}
                                        image={require ("./../img/tab4-2.jpg")}
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2" style={{textAlign : 'left'}}> 
                                            <p>Attract Customers and Accelerate Growth</p>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p"
                                            style={{textAlign : 'justify', fontSize : '16px'}}
                                        >
                                        <p >Capture market opportunities and grow your business with our order management, best execution and risk management capabilities for multiple asset classes and instrument types</p>
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Col>
                        <Col sm={4}>
                            <Card>
                                <CardActionArea>
                                    <CardMedia style={{height : 190}}
                                        image={require ("./../img/tab4-3.jpg")}
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2" style={{textAlign : 'left'}}> 
                                            <p>Discover Multi-asset-class Capabilities</p>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p"
                                            style={{textAlign : 'justify', fontSize : '16px'}}
                                        >
                                        <p >Take advantage of growth opportunities with best execution and a flexible order management and portfolio management system (PMS) that can support any asset class or trading strategy</p>
                                        <p></p>
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Col>
                    </Row>


                    <br />

                    <Row>
                        <Col sm={4}>
                            <Card>
                                <CardActionArea>
                                    <CardMedia style={{height : 180}}
                                        image={require ("./../img/tab4-4.jpg")}
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2" style={{textAlign : 'left'}}> 
                                            <p>How Imperial Solutions Empowers Equities, Futures and Listed Derivatives Trading</p>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p"
                                            style={{textAlign : 'justify', fontSize : '16px'}}
                                        >
                                        <p >Streamline trading, reduce costs and enable new business opportunities across asset classes</p>
                                        <p><br /><br /></p>
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Col>
                        <Col sm={4}>
                            <Card>
                                <CardActionArea>
                                    <CardMedia style={{height : 190}}
                                        image={require ("./../img/tab4-5.jpg")}
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2" style={{textAlign : 'left'}}> 
                                            <p>Access Data and Risk Insights in Real Time</p>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p"
                                            style={{textAlign : 'justify', fontSize : '16px'}}
                                        >
                                        <p >Get real-time position control, performance data and risk analytics at your fingertips to support your trading and investment strategies, no matter how complex</p>
                                        <p><br /><br /></p>
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Col>
                        <Col sm={4}>
                            <Card>
                                <CardActionArea>
                                    <CardMedia style={{height : 187}}
                                        image={require ("./../img/tab4-6.jpg")}
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2" style={{textAlign : 'left'}}> 
                                            <p>Improve Cost Efficiencies {'&'} Trade at Lightning Speed</p>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p"
                                            style={{textAlign : 'justify', fontSize : '16px'}}
                                        >
                                        <p >Reduce your total cost of ownership with our modular and cost-effective trading technology. We offer real-time trading, with low-latency multi-asset trading capabilities and unrivalled flexibility, all powered by super-fast connectivity to markets worldwide</p>
                                        <p></p>
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Col>
                    </Row>

                <br />

                    <Row style={{marginBottom : '5%'}}>
                        <Col sm={6}>
                            <Card>
                                <CardActionArea>
                                    <CardMedia style={{height : 190}}
                                        image={require ("./../img/tab4-7.jpg")}
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2" style={{textAlign : 'left'}}> 
                                            <p>In Trading, Every Second Matter</p>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p"
                                            style={{textAlign : 'justify', fontSize : '16px'}}
                                        >
                                        <p >Investment banks and broker dealers are under intense pressure to increase competitiveness and manage the complexity of trading across geographies. Our trading software, portfolio management, market data and connectivity capabilities provide next-generation trading solutions. Regardless of asset class or strategy, you’ll have the tools you need to stay competitive, across OTC, electronic trading and cross-asset investment strategies. With greater flexibility, smarter integrations and real-time speed, you can connect your front office for the future.</p>
                                        <p></p>
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Col>
                        <Col sm={6}>
                            <Card>
                                <CardActionArea>
                                    <CardMedia style={{height : 195}}
                                        image={require ("./../img/tab4-8.jpg")}
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2" style={{textAlign : 'left'}}> 
                                            <p>Switch On Next Generation Trading Technology</p>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p"
                                            style={{textAlign : 'justify', fontSize : '16px'}}
                                        >
                                        <p >We provide real-time trading technology solution that offers trading of all listed asset classes across over 150 markets globally as well as client application and broker connectivity to global markets. Leverage our flexible, high-performance OMS, achieve best execution, and discover the value of a modular platform that can be delivered as a managed service.</p>
                                        <p><br /><br /></p>
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Col>
                    </Row>


                    {/* <Row>
                        <Col sm={6}>
                            <img src={Data1} width="16%" alt="Data1" />
                            <h5 ></h5>
                            <img src={Data2} width="16%" alt="Data1" />
                            <h5 >Discover Multi-asset-class Capabilities</h5>
                            <p >Take advantage of growth opportunities with best execution and a flexible order management and portfolio management system (PMS) that can support any asset class or trading strategy</p>
                            <img src={Data3} width="16%" alt="Data1" />
                            <h5 >Access Data and Risk Insights in Real Time</h5>
                            <p>Get real-time position control, performance data and risk analytics at your fingertips to support your trading and investment strategies, no matter how complex</p>
                            <img src={Data4} width="16%" alt="Data1" />
                            <h5 >In Trading, Every Second Matter</h5>
                            <p>Investment banks and broker dealers are under intense pressure to increase competitiveness and manage the complexity of trading across geographies. Our trading software, portfolio management, market data and connectivity capabilities provide next-generation trading solutions. Regardless of asset class or strategy, you’ll have the tools you need to stay competitive, across OTC, electronic trading and cross-asset investment strategies. With greater flexibility, smarter integrations and real-time speed, you can connect your front office for the future.</p>
                        </Col>
                        <Col sm={6}>
                            <img src={Data5} width="16%" alt="Data1" />
                            <h5>Attract Customers and Accelerate Growth</h5>
                            <p>Capture market opportunities and grow your business with our order management, best execution and risk management capabilities for multiple asset classes and instrument types</p>

                            <img src={Data6} width="16%" alt="Data1" />
                            <h5>How Imperial Solutions Empowers Equities, Futures and Listed Derivatives Trading</h5>
                            <p>Streamline trading, reduce costs and enable new business opportunities across asset classes</p>
                            <img src={Data7} width="16%" alt="Data1" />
                            <h5>Improve Cost Efficiencies & Trade at Lightning Speed</h5>
                            <p >Reduce your total cost of ownership with our modular and cost-effective trading technology. We offer real-time trading, with low-latency multi-asset trading capabilities and unrivalled flexibility, all powered by super-fast connectivity to markets worldwide</p>
                            <img src={Data8} width="16%" alt="Data1" />
                            <h5>Switch On Next Generation Trading Technology</h5>
                            <p style={{ marginBottom: "100px" }}> We provide real-time trading technology solution that offers trading of all listed asset classes across over 150 markets globally as well as client application and broker connectivity to global markets. Leverage our flexible, high-performance OMS, achieve best execution, and discover the value of a modular platform that can be delivered as a managed service. </p>
                        </Col>
                    </Row> */}
                </Container>
                <Footer />
            </div>
        )
    }
}

export default Data;