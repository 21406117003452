import React from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Header from '../header/index';
import Footer from '../footer/index'
import Contactus from '../images/project-3b.jpg'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Contactt from '../images/contact.jpg'

class Contact extends React.Component{
    render(){
        return(
            <div>
                <Header />
                <img src={Contactt} width="100%" alt="Contact" />
                <Container>
                    <Row>
                    <Col sm={3}>
                    
                    </Col>
                        <Col sm={6}>
                        <img src={Contactus} style={{width:'80%',borderRadius:"10px",marginBottom:'20px'}}  />
                        </Col>
                        <Col sm={3}>
                    
                    </Col>
                    </Row>
                </Container>
                
                <Footer />
            </div>
        )
    }
}


export default Contact;