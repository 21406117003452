import React from "react";
import Logo from '../img/logo.png'
import { Navbar, Nav, NavDropdown, NavItem, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';

import { LinkContainer } from 'react-router-bootstrap';
import './header.css';



class Header extends React.Component {

  render() {
    return (
      <div>

        <Navbar collapseOnSelect expand="lg" variant="dark" style={{backgroundColor : '#fff'}}>
          <Navbar.Brand href="/">
            <img
              src={Logo}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">

            </Nav>
            <Nav style={{ color: '#000' }}>
              <Nav.Link href="/" style={{ color: '#000' }}>HOME</Nav.Link>
              <Nav.Link href="/about" style={{ color: '#000' }}>ABOUT US</Nav.Link>

              <NavDropdown title={<span style={{ color: '#000' }}>OUR SERVICES</span>} id="collasible-nav-dropdown">
                <NavDropdown.Item href="/operation" style={{ color: '#000' }}>Operations And Processing</NavDropdown.Item>
                <NavDropdown.Item href="/data_analysis" style={{ color: '#000' }}>Market Data and Analysis</NavDropdown.Item>
                <NavDropdown.Item href="/consulting" style={{ color: '#000' }}>Functional Consulting </NavDropdown.Item>
              </NavDropdown>
            </Nav>

            <Form inline>

              {/* <Button variant="outlined" color="default" style={{ marginRight: '10px', marginLeft: "20px" }} >
                SignIn
              </Button> */}

              <Fab variant="contained" color="secondary" size="small" href="/signin" style={{ padding : '10px', marginRight: '10px', marginLeft: "20px" }}>
                Sign In
              </Fab>

              <Fab variant="contained" color="secondary" size="small" href="/signup" style={{ padding : '10px', marginRight: '10px', marginLeft: "10px" }}>
                Sign Up
              </Fab>

              {/* <Button variant="contained" color="primary" href="/signup">
                SignUp
              </Button> */}

            </Form>
          </Navbar.Collapse>
        </Navbar>

      </div>
    )
  }
}

export default Header;