import React from "react";
import Logo from '../images/logo.png'
import {Navbar,Nav,NavDropdown,NavItem,Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, BrowserRouter,NavLink as Router } from 'react-router-dom'
import { Form, } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import { LinkContainer } from 'react-router-bootstrap';
import {Redirect} from 'react-router-dom';


class Header extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        data:[],
        invoice : '',
        userFeed: '',
        redirectToReferrer: false,
        name:'',
        };
        this.logout = this.logout.bind(this);
        }
      componentWillMount(){
        if(sessionStorage.getItem("userData")){
        } 
        else{
        this.setState({redirectToReferrer: true});
        }
        }

    logout(){
        sessionStorage.setItem("userData",'');
        sessionStorage.clear();
        this.setState({redirectToReferrer: true});
        }
        
        render() {
        if (this.state.redirectToReferrer) {
        return (<Redirect to={'/'}/>)
        }
        
    
        return(
            <div>

                <Navbar collapseOnSelect expand="lg" bg="red" variant="dark">
                <Navbar.Brand href="/">
                  <img
                    src={Logo}
                    // width="50"
                    // height="30"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                  />
                </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                
              </Nav>
              <Nav>
                    {/* <Nav.Link href="/">HOME</Nav.Link>
                    <Nav.Link href="/about">ABOUT US</Nav.Link>
                   
                    <NavDropdown title="OUR SERVICES" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="/operation">Operations And Processing</NavDropdown.Item>
                    <NavDropdown.Item href="/data_analysis">Market Data and Analysis</NavDropdown.Item>
                    <NavDropdown.Item href="/management">Enterprise Management Solutions</NavDropdown.Item>
                    <NavDropdown.Item href="/crm">CRM Solutions</NavDropdown.Item>
                    <NavDropdown.Item href="/consulting">Functional Consulting </NavDropdown.Item>

                  
                    </NavDropdown> 
                    <Nav.Link eventKey={2} href="/contact">
                      CONTACT US 
                    </Nav.Link>
                     */}
              </Nav>

              <Form inline>
                
                  <Button variant="contained" color="secondary" style={{marginRight:'10px',marginLeft:"20px"}} onClick={this.logout}>
                    Logout
                  </Button>

                 
                     
                </Form>
            </Navbar.Collapse>
          </Navbar>

            </div>
        )
    }
}

export default Header;