import React from 'react'


class Footer extends React.Component{
    render(){
        return(
           
                <footer class="page-footer center-on-small-only pt-0 mt-5" style={{backgroundColor:"#fff", padding:'20px'}}>

                    <div class="footer-copyright">
                    <div class="container-fluid" style={{color:'#000', paddingTop:'10px', marginBottom : '-10px'}}>
                        © 2020 Imperial Solutions. All Rights Reserved.
                    </div>
                    </div>

                </footer>
            
        )
    }
}


export default Footer;